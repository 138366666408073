import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import { openSnackbar, VmsDialogRowAutocomplete, VmsInput } from 'components';
import moment from 'moment';
import { useEffect, useReducer, useState } from 'react';
import commonStyle from 'assets/jss/commonStyles';
import {
  ChevronLeft,
  Mail,
  People,
  PersonOutline,
  PhoneIphone,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  date_formats,
  template_categories,
  template_types,
  type_constants,
  user_roles,
  user_status,
  validatePhoneNumber,
  visit_visitor_status_constants,
} from 'AppSettings';
import * as EmailValidator from 'email-validator';
import {
  ConfigurationService,
  EmailService,
  SmsService,
  TemplateService,
  UserService,
  VisitService,
} from 'services';
import useLoadData from '../../../dashboard/visitor/InviteVisitor/useLoadData';
import UserDocumentModel from 'services/models/UserDocumentModel';
import { NavLink } from 'react-router-dom';
import Auth from 'modules/Auth';
import i18n from 'assets/i18n';
import TermsDialog from 'containers/TermsDialog';
import Aux from 'hoc/Auxiliary';
import 'react-international-phone/style.css';
import VmsDialogRowPhonuNumber from 'components/common/dialogs/rows/VmsDialogRowPhonuNumber';
import { colors } from 'assets/UISettings';
const styles = (theme) => ({
  ...commonStyle(theme),
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    width: '80%',
    margin: '0 auto',
  },
  sendButton: {
    color: 'white',
    fontSize: '1.3em',
    height: theme.spacing(8),
    width: '70%',
    marginLeft: '15%',
  },
  selfRegForm: {
    width: '50%',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.1)',
    padding: '70px',
    marginTop: '-15%',
  },
  formRow: {
    flex: '1',
    margin: '0 10px',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  hidden: {
    visibility: 'hidden',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px',
  },
  navLinkBack: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    backgroundColor: 'orange',
    borderRadius: '50%',
    color: 'white',
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: '1.3em',
    position: 'absolute',
  },
  phoneDialogRow: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(),
  },
  phoneHintDialog: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(),
    marginTop: '5px',
    fontSize: '15px',
    color: colors.orangeYellow,
  },
});

const reducer = (state, action) => {
  const fieldValue = action.payload;
  if (action.type === 'suggestedHost') {
    const host = action.payload.name;
    const host_id = action.payload._id;
    return { ...state, host, host_id };
  }
  switch (action.type) {
    case 'phone':
      const phone = fieldValue;
      return { ...state, phone };
    case 'email':
      const email = fieldValue;
      return { ...state, email };
    case 'host':
      const host = fieldValue;
      return { ...state, host };
    case 'company':
      const company = fieldValue;
      return { ...state, company };
    case 'name':
      const name = fieldValue;
      return { ...state, name };
    case 'purpose':
      const purpose = fieldValue;
      return { ...state, purpose };
    case 'note':
      const note = fieldValue;
      return { ...state, note };
    case 'removeHost':
      const host_id = fieldValue.host_id;
      return { ...state, host_id };
    case 'user_id':
      const user_id = fieldValue;
      return { ...state, user_id };
    case 'language':
      const language = fieldValue;
      return { ...state, language };
    case 'phoneHint':
      const phoneHint = fieldValue;
      return { ...state, phoneHint };
    default:
      return state;
  }
};

export const emptyInvitation = (visitorType) => {
  return {
    user_id: undefined,
    group: null,
    name: '',
    email: '',
    delegate: '',
    phone: '',
    arrival: moment(new Date()).toDate(),
    departure: moment(new Date()).endOf('day').toDate(),
    language: i18n.language,
    purpose: '',
    company: '',
    accompanimentRequired: false,
    confirmationRequired: false,
    emailShared: false,
    emailSharedEnabled: true,
    securityItems: [],
    emailLogs: [],
    host: '',
    host_id: null,
    wifi_username: '',
    wifi_password: '',
    acompaniments: [],
    inspectionActivities: [],
    user_status: user_status.PENDING,
    note: '',
    login: '',
    visitorType: visitorType,
    visitorTypes: [visitorType],
    authenticationRequired: false,
    documents: [],
    dragDropFileState: null,
    dragDropState: null,
    oldPhone: '',
    tenantId: null,
    porterNote: '',
    wifi_enabled: false,
    option1: false,
    activationUrlPlain: ' ',
    activationUrl: ' ', // empty space for template data replacement to work
    events: [],
    photo: null,
    photoUrl: null,
    option2: false,
    daysOff: [],
    datesOff: [],
    status: visit_visitor_status_constants.INVITED,
    signInSelfRegistration: false,
    agreeTerms: false,
    phoneHint: false,
  };
};
const SelfRegistrationPage = ({
  classes,
  login,
  navigate,
  findTemplate,
  visitorTypeParam,
  language,
}) => {
  const [t] = useTranslation();

  const [state, dispatch] = useReducer(
    reducer,
    emptyInvitation(visitorTypeParam)
  );

  const [showTerms, setShowTerms] = useState(false);

  const [terms, setTerms] = useState({
    agreeTerms: false,
    agreePrivacy: false,
    agreeBehaviour: false,
  });

  const [useBehaviour, setUseBehaviour] = useState(true);

  const [disablePop, setDisablePop] = useState(null);

  const [visitorType, visit_purposes, documents] = useLoadData(dispatch);

  const isPhoneRequired = Boolean(!state.email && state.phone?.length > 5);
  const isPhoneValid = !validatePhoneNumber(state.phone);
  const isEmailValid = !state.email || EmailValidator.validate(state.email);

  const nextDisabled =
    useBehaviour === 'true'
      ? terms.agreePrivacy === false || terms.agreeBehaviour === false
      : terms.agreePrivacy === false;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await ConfigurationService.getUseBehaviourRulesConfig();
      setUseBehaviour(response);
    } catch (e) {
      console.log(e);
    }
  };

  const updateTerms = (propertyName, value) => {
    setTerms({ ...terms, [propertyName]: value });
  };

  const handlePhoneChange = (value, phoneInfo) => {
    console.log('phone', phoneInfo);
    if (value?.length < 18) {
      dispatch({ type: 'phone', payload: value });
    }
    //check if the first number after dial code is 0
    const dialCodeLength = (phoneInfo?.country?.dialCode?.length || 0) + 1;
    const firstDigitAfterDialCode = value.charAt(dialCodeLength);
    dispatch({ type: 'phoneHint', payload: firstDigitAfterDialCode === '0' });
  };

  const handleEmailChange = (e) => {
    dispatch({ type: 'email', payload: e.target.value });
  };

  const handleHostSuggestionsClearRequested = () => (event) => {
    dispatch({
      type: 'removeHost',
      payload: { host_id: null },
    });
  };

  const handleSuggestionHostSelected = (suggestion) => (event) => {
    dispatch({ type: 'suggestedHost', payload: suggestion });
  };

  const handleHostChange = () => (event) => {
    dispatch({ type: 'host', payload: event.target.value });

    setDisablePop(event.target.value.length >= 4);
  };

  const handleChangeName = (event) => {
    dispatch({ type: 'name', payload: event.target.value });
  };

  const handleCreateSelfRegistredInvitation = async () => {
    try {
      if (state.phone === '' && state.email === '') {
        openSnackbar('enter_email_or_phone_number');
        return;
      }
      if (!state.host_id) {
        openSnackbar('please_enter_host');
        return;
      }
      if (!state.visitorType) {
        openSnackbar('please_choose_visitor_type');
        return;
      }
      let user;
      if (state.email !== '') {
        if (isEmailValid) {
          const email = await checkEmail();
          if (email) {
            user = await UserService.getUser(state.email);
            user.name = state.name;
            user.language = state.language;
            UserService.updateUserWithData(user._id, {
              name: state.name,
              language: state.language,
            });
          }
          if (!isPhoneValid) {
            handlePhoneChange('');
          }
        } else {
          openSnackbar(t('email_is_not_valid'));
          return;
        }
      }
      if (state.phone !== '') {
        if (state.phoneHint) {
          openSnackbar(t('phone_number_hint'));
          return;
        }
        if (isPhoneValid) {
          const phone = await checkPhone();
          if (phone) {
            user = user ? user : await UserService.getUserByPhone(state.phone);
          }
        } else if (!isEmailValid) {
          openSnackbar(t('phone_is_not_valid'));
          return;
        } else {
          handlePhoneChange('');
        }
      }
      const visitor = findVisitorType();
      if (!user) {
        const data = state;
        if (!isPhoneValid) {
          console.log('som tu');
          data.phone = '';
        }
        let createdUser = await createUser(data);
        user = await UserService.getUser(createdUser._id);
      }
      if (
        !(
          user?.status === user_status.ACTIVE ||
          user?.status === user_status.PENDING ||
          user?.message === 'Successfuly created new user.'
        )
      ) {
        openSnackbar(t('user_is_not_active_or_pending'));
        return;
      }
      dispatch({ type: 'user_id', payload: user._id });
      const addedDocuments = await UserService.getUserDocuments(user._id);
      if (visitor) await loadDocuments(visitor);

      checkForAddedDocuments(addedDocuments);

      sendNotification(user);
      const data = state;
      console.log('valid', isPhoneValid);
      if (!isPhoneValid) {
        console.log('som tu');
        data.phone = '';
      }
      const { created } = await VisitService.invite(
        user._id,
        state.visitorType,
        null,
        data
      );
      await addUserDocuments(user, state.documents, created[user._id]);

      await addInspectionActivities(
        created[user._id],
        state.inspectionActivities
      );
      if (user.agreeTerms || user_roles.ADMIN === user.role) {
        login(user);
        i18n.changeLanguage(state.language);
        navigate.push('/');
      } else {
        setShowTerms(true);
      }
    } catch (e) {
      openSnackbar(e.message);
      console.log('error', e);
    }
  };

  const sendNotification = async (user) => {
    try {
      if (state.confirmationRequired) {
        const host = await UserService.getUser(state.host_id);

        const company = await TemplateService.getCompanyData(
          Auth.getUser().tenantId
        );
        const data = {
          invite: {
            host: host.name,
            visitor: state.name,
            arrival: moment(state.arrival),
            departure: moment(state.departure).format(
              date_formats.full_date_long
            ),
          },
          company: company,
        };
        let replacedTemplate;

        if (host.email) {
          replacedTemplate = await findTemplate(
            template_categories.EMAIL,
            template_types.SELF_REGISTRATION_CREATED,
            user,
            data
          );

          if (replacedTemplate) {
            EmailService.sendTemplate(host.email, replacedTemplate);
          } else {
            openSnackbar(t('email_template_not_found'));
          }
        }

        if (!host.email && host.phone) {
          replacedTemplate = await findTemplate(
            template_categories.SMS,
            template_types.SELF_REGISTRATION_CREATED,
            user,
            data
          );

          if (replacedTemplate) {
            SmsService.sendTemplate(host.phone, replacedTemplate);
          } else {
            openSnackbar(t('sms_template_not_found'));
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const agreeToTerms = async () => {
    try {
      await UserService.updateUserWithData(state.user_id, {
        agreeTerms: terms.agreeTerms,
      });
      setShowTerms(false);
      login(await UserService.getUser(state.user_id));
      navigate.push('/');
    } catch (err) {
      console.log(err);
      openSnackbar(err);
    }
  };

  const checkForAddedDocuments = (documents) => {
    let newDocuments = [];
    state.documents.forEach((doc) => {
      let found = null;
      found = documents.find(
        (item) => doc.data.name === item.data.name && doc.type === item.type
      );
      if (!found) newDocuments.push(doc);
    });
    state.documents = newDocuments;
  };

  const loadDocuments = async (visitor) => {
    state.accompanimentRequired = visitor.value.accompanimentRequired;
    state.authenticationRequired = visitor.value.authenticationRequired;
    state.confirmationRequired = visitor.value.confirmationRequired;
    state.signInSelfRegistration = visitor.value.signInSelfRegistration;
    visitor.value.documents.forEach((doc) => {
      let type = undefined;
      switch (doc.type) {
        case 'document':
          type = 'documents';
          break;
        case 'signed':
          type = 'signed';
          break;
        case 'questionnaire':
          type = 'questionnaires';
          break;
        case 'feedback':
          type = 'feedback';
          break;
        default:
          break;
      }
      if (type) {
        const docData = documents[type].find(
          (document) => document.data.document_id === doc.document_id
        );
        if (docData) {
          const data = { ...docData.data };
          const newDoc = new UserDocumentModel(
            null,
            null,
            doc.type,
            docData.status,
            data
          );

          const userDocuments = [...state.documents];
          let found = false;
          if (docData.type === type_constants.FEEDBACK) {
            found = userDocuments.find(
              (document) =>
                document.data.document_id === newDoc.data.document_id &&
                (document.data.visit_id === newDoc.data.visit_id ||
                  document.data.visit_id === state.visit_id)
            );
          } else {
            found = userDocuments.find(
              (document) =>
                document.data.document_id === newDoc.data.document_id
            );
          }
          if (!found) {
            userDocuments.push(newDoc);
            state.documents = userDocuments;
          }
        }
      }
    });

    visitor.value.inspectionActivities?.forEach((activity) => {
      const inspectionActivities = [...state.inspectionActivities];
      if (!inspectionActivities.find((act) => act.type === activity)) {
        inspectionActivities.push({ type: activity });
      }
      state.inspectionActivities = inspectionActivities;
    });
  };

  const findVisitorType = () => {
    for (let i = 0; i < visitorType.length; i++) {
      if (visitorType[i].key === state.visitorType) {
        return visitorType[i];
      }
    }
  };

  const createUser = (invitation) => {
    const data = {
      name: invitation.name,
      email: invitation.email,
      company: invitation.company,
      phone: invitation.phone,
      language: invitation.language,
      role: user_roles.VISITOR,
      status: user_status.PENDING,
      emailShared: invitation.emailShared,
      login: invitation.login,
      agreeTerms: invitation.agreeTerms,
    };
    return UserService.create(data);
  };

  const checkEmail = async () => {
    try {
      const response = await UserService.checkEmailUsed(state.email);
      return response.exists;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const checkPhone = async () => {
    try {
      const response = await UserService.getUserByPhone(state.phone);
      return response;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const addUserDocuments = async (user, documents, visit_id) => {
    try {
      for (let i = 0; i < documents.length; i++) {
        let d = documents[i];
        if (!d._id) {
          if (d.type === type_constants.FEEDBACK) {
            d.data.visit_id = visit_id;
          }
          await UserService.addDocument(user._id, d.type, d.status, d.data);
        }
      }
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
    }
  };

  const addInspectionActivities = async (visit_id, inspectionActivities) => {
    try {
      for (let i = 0; i < inspectionActivities.length; i++) {
        if (!inspectionActivities[i]._id) {
          await VisitService.addInspectionActivity(
            visit_id,
            inspectionActivities[i]
          );
        }
      }
    } catch (err) {
      console.log(err);
      openSnackbar(err.message);
    }
  };

  return (
    <>
      <NavLink activeClassName={classes.active} to="/">
        <ChevronLeft className={classes.navLinkBack} />
      </NavLink>

      <div className={classes.container}>
        <div className={classes.selfRegForm}>
          <div className={classes.flexContainer}>
            <div className={classes.formRow}>
              <VmsInput
                rowClass={classes.dialogRow2}
                iconLeft={<PersonOutline />}
                inputName="name"
                textValue={state.name}
                placeholder={t('invite_form_name')}
                inputLabel={t('invite_form_name')}
                onChange={handleChangeName}
                required
              />
              <VmsInput
                rowClass={classes.dialogRow2}
                iconLeft={<Mail />}
                inputName="email"
                textValue={state.email}
                placeholder={`${t('invite_form_email')}${
                  isPhoneRequired ? ` (${t('required_phone')})` : ''
                }`}
                inputLabel={`${t('invite_form_email')}${
                  isPhoneRequired ? ` (${t('required_phone')})` : ''
                }`}
                onChange={handleEmailChange}
                required={!isPhoneRequired}
                inputError={!isEmailValid}
              />
              <VmsDialogRowPhonuNumber
                iconLeft={<PhoneIphone />}
                rowClass={classes.phoneDialogRow}
                hintClass={classes.phoneHintDialog}
                value={state.phone}
                required={isPhoneRequired}
                onChange={handlePhoneChange}
                phoneHint={state.phoneHint}
                classes={classes}
                language={language}
              />

              <VmsDialogRowAutocomplete
                rowClass={
                  state.name !== '' &&
                  ((isEmailValid && state.email !== '') ||
                    (isPhoneValid && state.phone !== ''))
                    ? classes.dialogRow2
                    : `${classes.dialogRow2}, ${classes.hidden}`
                }
                iconLeft={<People />}
                onSuggestionSelected={handleSuggestionHostSelected}
                onSuggestionsClearRequested={
                  handleHostSuggestionsClearRequested
                }
                suggestionService={UserService.getHostSuggestions}
                filterSuggestionsResponse={(response) => response}
                suggestionItemValue={(suggestion) => suggestion.name}
                onChange={handleHostChange}
                inputValue={state.host}
                inputName="host"
                placeholder={t('invite_form_host')}
                disablePop={disablePop}
                showAllSuggestions={false}
                required
              />
            </div>
          </div>
          <Button
            color="primary"
            className={classes.sendButton}
            variant="contained"
            onClick={handleCreateSelfRegistredInvitation}
          >
            {t('send_invitation')}
          </Button>
        </div>
      </div>
      <Aux>
        <Dialog
          open={showTerms}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {i18n.t('terms_of_use')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>{i18n.t('terms_of_user_changed')}</div>
              <TermsDialog onChange={updateTerms} useBehaviour={useBehaviour} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowTerms(false);
              }}
              color="primary"
            >
              {i18n.t('cancel')}
            </Button>
            <Button
              onClick={() => {
                agreeToTerms();
              }}
              color="primary"
              disabled={nextDisabled}
            >
              {i18n.t('next')}
            </Button>
          </DialogActions>
        </Dialog>
      </Aux>
    </>
  );
};
export default withStyles(styles)(SelfRegistrationPage);
