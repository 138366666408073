import { withStyles, Grid } from '@material-ui/core';
import i18n from 'assets/i18n';
import commonStyle from 'assets/jss/commonStyles';
import { colors } from 'assets/UISettings';
import { RequiredIcon } from 'components/common/icon';
import { useTranslation } from 'react-i18next';

import { PhoneInput, usePhoneInput } from 'react-international-phone';

const styles = (theme) => ({
  ...commonStyle(theme),
  PhoneInput: {
    '& .react-international-phone-input': {
      width: '100%',
      border: 'none !important',
      borderBottom: `1px solid ${colors.whiteFive} !important`,
      padding: '6px 0 7px !important',
      letterSpacing: 0.5,
      font: 'inherit !important',
    },
    '& .react-international-phone-input:focus': {
      borderBottom: `2px solid orange !important`,
    },
    '& .react-international-phone-input:hover': {
      borderBottom: `2px solid orange !important`,
    },

    '& div button': {
      border: 'none !important',
    },
  },
});

const VmsDialogRowPhoneNumber = (props) => {
  const {
    rowClass,
    iconLeft,
    classes,
    onChange,
    value,
    required,
    disabled,
    phoneHint,
    language,
  } = props;
  const [t] = useTranslation();
  const languageMapping = () => {
    if (language === 'nlbe' || language === 'befr') return 'be';
    if (language === 'en' || language === '') return 'gb';
    return language;
  };
  return (
    <>
      <Grid
        container
        className={rowClass}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        {iconLeft && (
          <Grid item>
            <div className={classes.icon}>
              <RequiredIcon required={required}>{iconLeft}</RequiredIcon>
            </div>
          </Grid>
        )}
        <Grid item xs>
          <PhoneInput
            className={classes.PhoneInput}
            initialValueFormat="international"
            focusInputOnCountrySelection={true}
            international={true}
            defaultCountry={languageMapping()}
            value={value}
            onChange={(phone, phoneInfo) => onChange(phone, phoneInfo)}
            disabled={disabled}
          />
        </Grid>
      </Grid>

      <div
        container
        className={classes.phoneHintDialog}
        style={{
          visibility: phoneHint ? 'visible' : 'hidden',
        }}
        alignItems="center"
      >
        {t('phone_number_hint')}
      </div>
    </>
  );
};

export default withStyles(styles)(VmsDialogRowPhoneNumber);
