import { Button, withStyles } from '@material-ui/core';
import { signing_tablet_screens, tablet_language_mapping } from 'AppSettings';
import i18n from 'assets/i18n';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationService } from 'services';

const styles = (theme) => ({
  mainLanguageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  languageItem: {
    margin: '10px',
    height: '12%',
    width: '10%',
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.2)',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    fontSize: 'large',
    fontWeight: 'bold',
    height: '12%',
    marginBottom: '70px',
    width: '24%',
  },
  specificLangContainer: {
    height: '100%',
    width: '100%',
  },
  mainLanguageItem: {
    height: '28%',
    width: '20%',
    alignSelf: 'center',
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.1)',
  },
  mainFlagContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '40px',
    height: '80%',
    width: '100%',
    flexWrap: 'wrap',
  },
  allLanguageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    height: '100%',
    alignItems: 'center',
  },
});

const LanguageMenu = ({ classes, changeTabletScreen, changePhoneLanguage }) => {
  const [t] = useTranslation();

  const [specificLanguages, setSpecificLanguages] = useState(true);

  const [mainLanguages, setMainLanguages] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await ConfigurationService.getValueByKey(
        'signing_tablet_main_languages'
      );
      const value = response.value.split(',').map((value) => value.trim());
      for (const flag of value) {
        if (!tablet_language_mapping.hasOwnProperty(flag)) {
          setMainLanguages(['en', 'sk', 'nlbe', 'de']);
          return;
        }
      }
      setMainLanguages(value);
    } catch (e) {
      setMainLanguages(['en', 'sk', 'nlbe', 'de']);
      console.log(e);
    }
  };

  const changeLanguage = (lang) => {
    console.log(i18n.language);
    if (lang === 'befr') {
      i18n.changeLanguage('fr');
    } else {
      i18n.changeLanguage(lang);
    }
    changePhoneLanguage(lang);
    changeTabletScreen(signing_tablet_screens.LANDINGPAGE);
  };

  return (
    <>
      {specificLanguages ? (
        <>
          <div className={classes.mainLanguageContainer}>
            <div className={classes.mainFlagContainer}>
              {mainLanguages.map((lang) => (
                <img
                  className={classes.mainLanguageItem}
                  src={tablet_language_mapping[lang]}
                  alt={t(`menu_language_${lang}`)}
                  onClick={() => {
                    changeLanguage(lang);
                  }}
                />
              ))}
            </div>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                setSpecificLanguages(false);
              }}
            >
              {t('all_languages')}
            </Button>
          </div>
        </>
      ) : (
        <div className={classes.allLanguageContainer}>
          {Object.keys(tablet_language_mapping).map((lang) => (
            <img
              className={classes.languageItem}
              src={tablet_language_mapping[lang]}
              alt={t(`menu_language_${lang}`)}
              onClick={() => changeLanguage(lang)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(LanguageMenu);
