import { withStyles, Grid, Typography } from '@material-ui/core';
import { VmsButtonFAB } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VisitService from 'services/VisitService';
import {
  People,
  Email,
  PhoneIphone,
  AccessTime,
  BusinessCenter,
  Place,
  EventNote,
  WatchLater,
  Map,
  Person,
} from '@material-ui/icons';
import {
  user_document_status_constants,
  routes,
  user_roles,
  type_constants,
  visit_event_status_constants,
} from 'AppSettings';
import moment from 'moment';
import { ConfigurationService, UserService } from 'services';
import QRCode from 'qrcode.react';
import Auth from 'modules/Auth';

const styles = (theme) => ({
  sideBar: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    minWidth: '16%',
    maxWidth: '30%',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    position: 'relative',
  },
  fab: {
    right: theme.spacing(2),
    bottom: theme.spacing(9),
    position: 'absolute',
    margin: 0,
    color: theme.palette.common.white,
  },

  infoWrapper: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
    overflow: 'hidden',
  },
  dialog: {
    marginBottom: theme.spacing(4),
  },

  item: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.primary4,
  },
  itemText: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.primary.main,
  },
  visitIcon: {
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(),
  },
  contact: {
    color: theme.palette.primary4,
  },
  hints: {
    width: '21%',
    padding: '2%',
  },
});

const SideBarInfo = (props) => {
  const { user, classes, history } = props;

  const [t] = useTranslation();

  const [data, setData] = useState(undefined);

  const [disablePrint, setDisablePrint] = useState(true);

  const loadData = async () => {
    try {
      if (
        [user_roles.ADMIN, user_roles.EMPLOYEE, user_roles.ASSISTANT].includes(
          user.role
        ) &&
        user.tenantId === Auth.getUser().tenantId
      ) {
        setData(user);
      } else {
        await loadVisit();
      }

      const documents = await UserService.getNonPersonalDocuments(user._id);
      if (documents.length === 0) {
        setDisablePrint(false);
      }
      for (let index = 0; index < documents.length; index++) {
        if (
          documents[index].status ===
            user_document_status_constants.COMPLETED ||
          documents[index].status === user_document_status_constants.SIGNED ||
          documents[index].status === user_document_status_constants.READ ||
          documents[index].type === type_constants.FEEDBACK
        ) {
          setDisablePrint(false);
          const conf = await ConfigurationService.getValueByKey(
            'signing_tablet_auto_print'
          );
          if (conf?.value === 'true') {
            handlePrintBadge();
          }
        } else {
          setDisablePrint(true);
          break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const loadVisit = async () => {
    const res = await VisitService.getVisitByUser(user._id);
    if (res) {
      setData(res);
    }
  };

  const handlePrintBadge = async () => {
    try {
      if (
        [user_roles.ADMIN, user_roles.EMPLOYEE, user_roles.ASSISTANT].includes(
          user.role
        ) &&
        user.tenantId === Auth.getUser().tenantId
      ) {
        const visit = {
          securityItem: user.securityItem,
          user: user,
        };
        history.push(routes.PRINT_VISIT, { visit: visit });
      } else {
        try {
          if (data?.visit?.signInSelfRegistration) {
            await VisitService.addEvent(
              data.visit._id,
              visit_event_status_constants.SIGNED_IN,
              moment(new Date()).toDate()
            );
          }
        } catch (err) {
          console.log(err);
        }

        console.log('data', data);
        let visit;
        if (!data) {
          const res = await VisitService.getVisitByUser(user._id);
          visit = res.visit;
        } else {
          visit = data.visit;
        }
        history.push(routes.PRINT_VISIT, {
          visit: { ...visit, user: user },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {data ? (
        <div className={classes.sideBar}>
          {data.visit && (
            <div className={classes.infoWrapper}>
              <Grid className={classes.item} item>
                <People className={classes.visitIcon} />
                <Typography variant="body1" className={classes.contact} noWrap>
                  {data.visit.host.name}
                </Typography>
              </Grid>
              {user.email && (
                <Grid className={classes.item} item>
                  <Email className={classes.visitIcon} />
                  <Typography
                    variant="body1"
                    className={classes.contact}
                    noWrap
                  >
                    {user.email}
                  </Typography>
                </Grid>
              )}
              {user.phone && (
                <Grid className={classes.item} item>
                  <PhoneIphone className={classes.visitIcon} />
                  <Typography
                    variant="body1"
                    className={classes.contact}
                    noWrap
                  >
                    {user.phone}
                  </Typography>
                </Grid>
              )}

              <Grid className={classes.item} item>
                <AccessTime className={classes.visitIcon} />
                <Typography variant="body1" className={classes.contact} noWrap>
                  {`${moment(data.visit.timeFrom).format('LL HH:mm')} ${
                    data.company.timezone
                  }`}
                </Typography>
              </Grid>

              <Grid className={classes.item} item>
                <EventNote className={classes.visitIcon} />
                <Typography variant="body1" className={classes.contact} noWrap>
                  {data.visit.note}
                </Typography>
              </Grid>

              <Grid className={classes.item} item>
                <BusinessCenter className={classes.visitIcon} />
                <Typography variant="body1" className={classes.contact} noWrap>
                  {data.visit.host.company}
                </Typography>
              </Grid>

              <Grid className={classes.item} item>
                <Map className={classes.visitIcon} />
                <Typography variant="body1" className={classes.contact} noWrap>
                  {data.company.description}
                </Typography>
              </Grid>

              <Grid className={classes.item} item>
                <WatchLater className={classes.visitIcon} />
                <Typography variant="body1" className={classes.contact} noWrap>
                  {`${moment(data.visit.timeTo).format('LL HH:mm')} ${
                    data.company.timezone
                  }`}
                </Typography>
              </Grid>

              <Grid className={classes.item} item>
                <Place className={classes.visitIcon} />
                <Typography variant="body1" className={classes.contact} noWrap>
                  {data.visit.purpose}
                </Typography>
              </Grid>
              <Grid className={classes.itemText} item>
                {data.visit.confirmationRequired ? (
                  <p>{t('hint_for_self_registartion_visitor')}</p>
                ) : (
                  <p>{t('hint_for_print_qr_code')}</p>
                )}
              </Grid>
            </div>
          )}
          {[
            user_roles.ADMIN,
            user_roles.EMPLOYEE,
            user_roles.ASSISTANT,
          ].includes(user.role) &&
            user.tenantId === Auth.getUser().tenantId && (
              <div className={classes.infoWrapper}>
                <Grid className={classes.item} item>
                  <Person className={classes.visitIcon} />
                  <Typography
                    variant="body1"
                    className={classes.contact}
                    noWrap
                  >
                    {user.name}
                  </Typography>
                </Grid>
                <Grid className={classes.item} item>
                  <People className={classes.visitIcon} />
                  <Typography
                    variant="body1"
                    className={classes.contact}
                    noWrap
                  >
                    {user.role}
                  </Typography>
                </Grid>
                <Grid item>
                  <QRCode value={user.securityItem} size={254} />
                </Grid>
                <Grid className={classes.itemText} item>
                  <p>{t('hint_for_employee')}</p>
                </Grid>
              </div>
            )}
          <VmsButtonFAB
            variant="extended"
            customStyle={classes.fab}
            disabled={disablePrint}
            onClick={handlePrintBadge}
          >
            {t('print_your_qr_code')}
          </VmsButtonFAB>
        </div>
      ) : null}
    </>
  );
};

export default withStyles(styles)(SideBarInfo);
